<template>
  <v-container fluid>
    <v-card>
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'card-header']">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t('analytics.lang_itemSalesTime') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">
          <b-dropdown no-caret right toggle-class="btn-icon btn-icon-only" :menu-class="[this.$vuetify.theme.dark? 'dark-bg' : '']" variant="link">
            <span slot="button-content"><i class="pe-7s-menu btn-icon-wrapper"></i></span>
            <div>
              <h6 class="dropdown-header" tabindex="-1">
                {{ $t('generic.lang_Options') }}</h6>
              <button  :class="[this.$vuetify.theme.dark? 'white--text' : '', 'dropdown-item']" tabindex="0" type="button"
              >
                <v-icon class="dropdown-icon" color="warning">picture_as_pdf</v-icon>
                <span class="text-capitalize">{{ $t('generic.lang_export') }} PDF</span>
              </button>

              <button :class="[this.$vuetify.theme.dark? 'white--text' : '', 'dropdown-item']" tabindex="0" type="button" >
                <font-awesome-icon class="dropdown-icon success--text"
                                   icon="file-excel"/>
                <span>Excel</span>
              </button>
            </div>
          </b-dropdown>
        </div>
      </v-card-title>
      <v-card-text class="mt-6">
        <v-row align="center" justify="center">
          <v-col class="pb-0 mb-0" cols='12' sm="6">
            <v-autocomplete
              v-model="itemID"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              item-text="name"
              item-value="id"
              :label="$t('erp.lang_items')"
              clearable
              outlined
              dense
              flat
              hide-details
              cache-items
            ></v-autocomplete>
          </v-col>

          <v-col class="pb-0 mb-0" cols="12" sm="6">
            <BaseDateInput dense :label="$t('generic.lang_selectDay')" outlined type="date" v-model="dayDate" hide-details/>
          </v-col>

          <v-col class="mt-0 pt-0" cols="12">
            <v-btn :disabled="this.loading" :loading="this.loading" @click="getSalesData" block
                   class="bg-primary text-light mx-auto">
              <v-icon class="ma-1">remove_red_eye</v-icon>
              {{this.$t('generic.lang_display')}}
            </v-btn>
          </v-col>
          <v-col class="pa-0 ma-0" cols="12">
            <v-divider class="pa-0 ma-0"/>
          </v-col>
          <v-col cols="12" class="mx-auto">
            <v-card v-if="showTopChart">
              <v-card-text class="pa-0">
                <apexchart v-show="!loading" height="700" type="bar"  :options="chartOptions" :series="series"></apexchart>
                <div v-if="loading" class="text-center">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card v-if="showTable">
              <v-card-text class="pa-0">
                <v-data-table
                    :items="dataToShow"
                    :headers="dataTableHeaders"
                >
                  <template v-slot:item.price="{item}">
                    {{ item.price | currency }}
                  </template>
                  <template v-slot:item.total="{item}">
                    {{ item.total | currency }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFileExcel,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {mapGetters} from "vuex";
import moment from 'moment'
import VueApexCharts from "vue-apexcharts";
import {Events} from "@/plugins/events";
import BaseDateInput from "@/components/common/BaseDateInput.vue";
import {ENDPOINTS} from "@/config";

library.add(
    faFileExcel
);

export default {
  name: "ItemSalesTimeComponent",
  components:{
    'apexchart': VueApexCharts,
    'font-awesome-icon': FontAwesomeIcon,
    BaseDateInput
  },
  data(){
    return{
      series: [{
        name: this.$t('generic.lang_itemsale'),
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0,0, 0]
      }],
      chartOptions: {
        chart: {
          stacked: true,
          type: 'bar',
          width:'100%',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          }
        },
        theme: {
          mode: this.$vuetify.theme.dark? 'dark' : 'light', 
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        labels: [],
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          title: {
            text: this.$t('generic.lang_time') + '(' + this.$t('generic.lang_hour') + ')',
          },

          type: 'time'
        },
        yaxis: [
          {
            seriesName: 'itemsale',
            opposite: false,
            axisTicks: {
              show: false,
            },
            labels: {
              formatter: (y) => {
                return this.$options.filters.currency(y);
              }
            },
            title: {
              text: this.$t('generic.lang_totalInEuro'),
            }
          },
        ],
        fill: {
          opacity: 1
        },
      },
      loading:false,
      showTopChart:false,
      showTable:false,
      itemID:null,
      dayDate:null,
      isLoading: false,
      items: [],
      search: null,
      awaitingSearch: null,
      dataTableHeaders:[
        {
          text: this.$t('generic.lang_soldQuantity'),
          value:'qty'
        },
        {
          text: this.$t('analytics.lang_itemSellPrice'),
          value:'price'
        },
        {
          text: this.$t('analytics.lang_totalSellPrice'),
          value:'total'
        },
      ],
      barChartOptions:[],
      dataToShow:[],
    }
  },
  watch: {
    search(val) {
      // Items have already been loaded
      // if (this.items.length > 0) return;

      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if ((val && val.length === 0) || !!val == false) {
          return;
        }

        this.isLoading = true;

        this.axios
          .post(ENDPOINTS.ERP.ITEM.SEARCH, {
            query: val,
          })
          .then((res) => {
            console.log(res.data);

            if (res.data.status === "SUCCESS") {
              this.items = res.data.data.foundItems;
            }
          })
          .catch(() => {})
          .finally(() => {
            this.isLoading = false;
          });
      }, 500);
    },
  },
  methods:{
    getSalesData() {
      this.loading = true;
      this.showTable=false;
      this.showTopChart=false
      let self=this;
      this.axios.post("get/analytics/wareanalytics/individualItemSales/", {
        itemId:this.itemID,
        startDate:moment(this.dayDate).startOf('day').unix(),
        endDate:moment(this.dayDate).endOf('day').unix(),
      }).then((res) => {

        if(res.data.success) {
          if(res.data.data.length ===0){
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_noDataToDisplay'),
              color: "warning"
            });
            return;
          }
          res.data.data.forEach(item=>{
            self.series[0].data[item.hour]=parseFloat(item.totalPricePerItem)*parseInt(item.qty);
          })
          this.showTopChart=true;
          this.dataTableSetup(res.data.data);
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_dataLoaded'),
            color: "success"
          });
        }else{
          Events.$emit("showSnackbar", {
            message: res.data.msg||this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(()=>{
        this.loading=false;
      });
    },
    dataTableSetup(items){
      let row={};
      let total=0;
      row.qty=0;
      items.forEach(item=>{
        row.qty+=parseInt(item.qty);
        row.price=item.totalPricePerItem
        total+=parseInt(item.qty)*parseFloat(item.totalPricePerItem)
      });
      row.total=total;
      this.dataToShow=[]
      this.dataToShow.push(row)
      this.showTable=true;
    }
  },
  mounted() {
    this.chartOptions.labels=[];
    
    let now = Date.now();
    this.dayDate = moment(now).format('YYYY-MM-DD');

    for(let hour = 0; hour < 24; hour++) {
      this.chartOptions.labels.push(hour+":00");
    }
  }
}
</script>

<style scoped>

</style>